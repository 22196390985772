<template>
	<div id="admin-home">
		<el-menu
			:default-active="activeIndex"
			class="el-menu-demo"
			mode="horizontal"
			@select="handleSelect"
		>
			<el-menu-item index="1">首页</el-menu-item>
			<el-submenu index="2">
				<template slot="title">材料</template>
				<el-menu-item index="2-1">赛事章程上传</el-menu-item>
				<el-menu-item index="2-2">协会文件上传</el-menu-item>
			</el-submenu>
			<el-menu-item index="3">比赛结果提交</el-menu-item>
			<el-submenu index="4">
				<template slot="title">查看</template>
				<el-menu-item index="4-1">比赛结果公示</el-menu-item>
				<el-menu-item index="4-2">院系选项情况</el-menu-item>
			</el-submenu>
			<el-menu-item index="5">发布赛事新闻</el-menu-item>
			<el-menu-item index="6">协会信息维护</el-menu-item>
			<el-menu-item index="7">修改密码</el-menu-item>
			<div id="hello-logout-button">
				<div id="logout-button" @click="logout()">
					<el-link> 退出<i class="el-icon-switch-button"></i> </el-link>
				</div>
				<div id="hello-user" v-if="this.userinfo">
					你好, {{ this.userinfo.username }}
				</div>
			</div>
		</el-menu>
		<div class="line"></div>
		<div
			id="content"
			style="
				margin-top: 15px;
				width: 80%;
				margin-left: auto;
				margin-right: auto;
			"
		>
			<router-view></router-view>
		</div>
	</div>
</template>

<style scoped>
#content {
	margin-top: 15px;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
}
#hello-user {
	float: right;
	height: 60px;
	line-height: 60px;
	margin: 0 16px 0 0;
	border-bottom: 2px solid transparent;
	color: #909399;
}
#logout-button {
	float: right;
	height: 60px;
	line-height: 60px;
	margin: 0 20px 0 0;
	border-bottom: 2px solid transparent;
	color: #909399;
}
#hello-logout-button:focus {
	outline: none;
}
</style>

<script>
import mixin from "../mixins/universalmixins.js";
export default {
	name: "ClubHome",
	data() {
		return {
			activeIndex: "1",
			gamelist: [],
		};
	},
	methods: {
		handleSelect(key) {
			// console.log(key, keyPath);
			switch (key) {
				case "1":
					this.$router.push("/club");
					break;
				case "2-1":
					this.$router.push("/club/upload-rules");
					break;
				case "2-2":
					this.$router.push("/club/upload-files");
					break;
				case "3":
					this.$router.push("/club/submit-result");
					break;
				case "4-1":
					this.$router.push("/club/resultlist");
					break;
				case "4-2":
					this.$router.push("/club/get-part-time-dept");
					break;
				case "5":
					this.$router.push("/club/add-news");
					break;
				case "6":
					this.$router.push("/club/update-info");
					break;
				case "7":
					this.$router.push("/club/change-password");
					break;
				default:
					break;
			}
		},
		changePage(pagename) {
			this.$router.push(pagename);
		},
	},
	mixins: [mixin],
};
</script>
